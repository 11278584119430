import React from "react"
import { Link } from "react-router-dom"
import { whatsAppLink } from "../static/data"
// import TeamMemberCard from "./TeamMemberCard";
import Header from "../components/Layout/Header"
import Footer from "../components/Layout/Footer"

const AboutPage = () => {
	return (
		<div>
			<Header />
			<div className="container mx-auto px-4 py-8">
				<section className="mb-12">
					<div className="max-w-3xl mx-auto">
						<h1 className="text-4xl font-bold text-gray-800 mb-4">About Us</h1>
						<p className="text-lg text-gray-700 mb-6">
						 Welcome to StarkMarts: Where Students Buy, Sell, and Grow Businesses! <br /> <br />

🎓Connecting University Communities and Empowering Student Entrepreneurs. <br />

StarkMarts isn’t just an online marketplace; it's a robust platform designed to support and empower student entrepreneurs across campuses. Whether you're buying essential supplies, selling products you’ve created, or looking to expand a budding business, StarkMarts provides the tools, knowledge, and reach you need to succeed.   <br /> <br />                             

Why Choose StarkMarts? <br />

- Exclusive University Network:Connect, buy, and sell within and beyond your own university, reaching a vast network of students and staff across multiple campuses. <br /> <br />

- Affiliate Marketing Program:Leverage our platform to not only sell your products but also to earn through promoting relevant products. Perfect for students looking to expand their marketing skills and generate additional revenue streams. <br /> <br />

- Business Growth Tools:Access valuable resources and expert guidance to grow your business. From tutorials on digital marketing to advice on product development, we support your entrepreneurial journey every step of the way. <br /> <br />

- Safe and Secure: With university email verification and secure payment systems, trust and safety are at the core of every transaction. <br />
<br />

Start Your StarkMarts Journey Today! <br />

1. Sign Up for Free: Quick setup with your university email to start buying or selling. <br /> <br />
2. Explore the Marketplace: Find everything you need for university life or discover new opportunities to expand your business. <br /><br />
3. Join Our Affiliate Program: Start earning by promoting products you believe in. <br /><br />
4. Grow and Learn: Utilize our tools and resources to expand your reach and enhance your business skills. 


						</p>
						<p className="text-lg text-gray-700 mb-6">
						Exclusive Features for Entrepreneurs: <br />

Cross-Campus Selling:Don't limit your market. Sell to students from other universities and grow your customer base. <br /><br />
Marketing Tools: Access our suite of marketing tools to effectively promote your products and track your success. <br /><br />
Expert Support: Get personalized support from business experts tailored to the needs of young entrepreneurs. <br /><br />
 StarkMarts Success Stories: Be inspired by fellow students who have turned their small ventures into thriving businesses. <br /><br />

Join StarkMarts Now! <br /> <br />

StarkMarts is more than just a platform, it’s a thriving community where students can not only buy and sell goods but also establish and expand their businesses. Whether you're setting up your first stall or ready to take your student business to the next level, StarkMarts is here to help you succeed. <br /> <br />

Sign Up and Start Selling and buying.
						</p>
						<Link
							to={whatsAppLink}
							className="inline-block bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded"
						>
							Contact Us
						</Link>
					</div>
				</section>
				{/* <section>
					<div className="max-w-3xl mx-auto">
						<h2 className="text-3xl font-bold text-gray-800 mb-4">Meet Our Team</h2>
						<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
							<TeamMemberCard name="John Doe" role="Co-Founder & CEO" imgSrc="/images/john-doe.jpg" />
							<TeamMemberCard
								name="Jane Smith"
								role="Chief Product Officer"
								imgSrc="/images/jane-smith.jpg"
							/>
						</div>
					</div>
				</section> */}
			</div>
			<Footer />
		</div>
	)
}

export default AboutPage
