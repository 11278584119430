import React, {useState, useEffect} from "react"
import { useParams } from "react-router-dom";
import EventCard from "../components/Events/EventCard"
import Header from "../components/Layout/Header"
import Footer from "../components/Layout/Footer"
import Loader from "../components/Layout/Loader"
import { server } from "../server"
import axios from "axios"
import { toast } from "react-toastify";

const EventsPage = () => {
	const { shopId } = useParams();
	const [loading, setLoading] = useState(true);
	const [events, setEvents] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${server}/event/get-all-events/${shopId}`);
				setEvents(response.data.events);
			} catch (err) {
				toast(err.message);
			} finally {
				setLoading(false);
			}
		};
	
		fetchData();
	
	}, [server, shopId]);

	return (
		<>
			{loading ? (
				<Loader />
			) : (
				<div>
					<Header activeHeading={4} />
					{events.length === 0 ? (
						<div className="flex items-center justify-center min-h-screen">
							<div className="text-center">
								<h1 className="text-4xl font-bold mb-4">No Events!</h1>
								<p className="text-lg">
									Whoops! There are no running events
									<br />
								</p>
							</div>
						</div>
					) : (
						<div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-4 xl:gap-[200px] mb-12">
							{events.length !== 0 && (
								events.map((i, index) => (
									<EventCard data={i} key={index} />
								))
							)}
						</div>
					)}
					<Footer/>
				</div>
			)}
		</>
	)
}

export default EventsPage
