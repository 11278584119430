import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { server } from "../server";
import { FaEnvelope } from "react-icons/fa";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const location = useLocation();
  const isSeller = new URLSearchParams(location.search).get("isSeller");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const endpoint = (isSeller ? "shop" : "user") + "/forgot-password";

    try {
      const res = await axios.post(`${server}/${endpoint}`, { email });

      toast.success(res.data.message);
      setSubmitted(true);
    } catch (err) {
      toast.error(err.response?.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-50 bg-opacity-75 z-50">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-300"></div>
        </div>
      )}
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        {!submitted ? (
          <>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Reset your password
            </h2>
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form className="space-y-6" onSubmit={handleSubmit}>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email address
                    </label>
                    <div className="mt-1">
                      <input
                        type="email"
                        name="email"
                        autoComplete="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                      disabled={loading}
                    >
                      Send Reset Link
                    </button>
                  </div>
                  <div className="text-sm text-center">
                    <Link to="/login" className="font-medium text-blue-600 hover:text-blue-500">
                      Back to Login
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </>
        ) : (
          <div className="text-center">
            <FaEnvelope className="mx-auto h-16 w-16 text-blue-500" />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Please check your mail
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              We have sent a password reset link to your email address.
            </p>
            <div className="mt-6">
              <Link to="/login" className="font-medium text-blue-600 hover:text-blue-500">
                Back to Login
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
