import axios from "axios";
import { server } from "../../server";

const token = JSON.parse(localStorage.getItem("token") || null ) ;

// create product
export const createProduct =
	({ name, description, category, tags, originalPrice, discountPrice, stock, shopId, images, video }) =>
	async (dispatch) => {
		try {
			dispatch({
				type: "productCreateRequest",
			});

			const { data } = await axios.post(
				`${server}/product/create-product`,

				{
					name,
					description,
					category,
					tags,
					originalPrice,
					discountPrice,
					stock,
					shopId,
					images,
					video,
				},

				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			dispatch({
				type: "productCreateSuccess",
				payload: data.product,
			});
		} catch (error) {
			dispatch({
				type: "productCreateFail",
				payload: error.response.data.message,
			});
		}
	};

// update product
export const updateProduct =
	({
		id,
		name,
		description,
		// category,
		// tags,
		originalPrice,
		discountPrice,
		stock,
		images,
		video,
	}) =>
	async (dispatch) => {
		try {
			dispatch({
				type: "productUpdateRequest",
			});

			const { data } = await axios.put(
				`${server}/product/${id}`,
				{ name, description, originalPrice, discountPrice, stock, images, video },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			dispatch({
				type: "productUpdateSuccess",
				payload: data.product,
			});
		} catch (error) {
			dispatch({
				type: "productUpdateFail",
				payload: error.response.data.message,
			});
		}
	};

// get All Products of a shop
export const getAllProductsShop = (id) => async (dispatch) => {
	try {
		dispatch({
			type: "getAllProductsShopRequest",
		});

		const { data } = await axios.get(`${server}/product/get-all-products-shop/${id}`);
		dispatch({
			type: "getAllProductsShopSuccess",
			payload: data.products,
		});
	} catch (error) {
		dispatch({
			type: "getAllProductsShopFailed",
			payload: error.response.data.message,
		});
	}
};

// delete product of a shop
export const deleteProduct = (id) => async (dispatch) => {
	try {
		dispatch({
			type: "deleteProductRequest",
		});

		const { data } = await axios.delete(`${server}/product/delete-shop-product/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		dispatch({
			type: "deleteProductSuccess",
			payload: data.message,
		});
	} catch (error) {
		dispatch({
			type: "deleteProductFailed",
			payload: error.response.data.message,
		});
	}
};

// get all products
export const getAllProducts = () => async (dispatch) => {
	try {
		const user = JSON.parse(localStorage.getItem("user"));

		dispatch({
			type: "getAllProductsRequest",
		});

		let fetchedProducts;
		if (user) {
			const response = await axios.get(`${server}/product/get-all-products?sch=${user.university}`);
			fetchedProducts = response.data.products;

			// If university-specific products are empty, fetch all products
			if (fetchedProducts.length === 0) {
				const fallbackResponse = await axios.get(`${server}/product/get-all-products`);
				fetchedProducts = fallbackResponse.data.products;
			}
		} else {
			const response = await axios.get(`${server}/product/get-all-products`);
			fetchedProducts = response.data.products;
		}

		dispatch({
			type: "getAllProductsSuccess",
			payload: fetchedProducts,
		});
	} catch (error) {
		dispatch({
			type: "getAllProductsFailed",
			payload: error.response?.data.message,
		});
	}
};
