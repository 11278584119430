import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { server } from '../../server';

const CustomShopRedirect = () => {
  const { customName } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchShopId = async () => {
      try {
        const response = await axios.get(`${server}/shop/get-shop-id/${customName}`);

        const data = response.data;
        
        if (data.shopId) {
          navigate(`/shop/preview/${data.shopId}`, { replace: true });
        } else {
          navigate('/404', { replace: true });
        }
      } catch (error) {
        navigate('/404', { replace: true });
      }
    };

    fetchShopId();
  }, [customName, navigate]);

  return <div>Redirecting...</div>;
};

export default CustomShopRedirect;