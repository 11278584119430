import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";
import { categoriesData } from "../../static/data";
import { AiOutlineHeart, AiOutlineSearch, AiOutlineShoppingCart, AiOutlineUser } from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { BiMenuAltLeft } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import DropDown from "./DropDown";
import Navbar from "./Navbar";
import { useSelector } from "react-redux";
import Cart from "../cart/Cart";
import Wishlist from "../Wishlist/Wishlist";
import { RxCross1 } from "react-icons/rx";
import logo from "../../Assests/logo-black.png";

const Header = ({ activeHeading }) => {
	const { user } = useSelector((state) => state.user);
	const { isSeller } = useSelector((state) => state.seller);
	const { wishlist } = useSelector((state) => state.wishlist);
	const { cart } = useSelector((state) => state.cart);
	const { allProducts } = useSelector((state) => state.products);
	const [searchTerm, setSearchTerm] = useState("");
	const [searchData, setSearchData] = useState(null);
	const [active, setActive] = useState(false);
	const [dropDown, setDropDown] = useState(false);
	const [openCart, setOpenCart] = useState(false);
	const [openWishlist, setOpenWishlist] = useState(false);
	const [open, setOpen] = useState(false);
	const [isFocused, setIsFocused] = useState(false);

	const isAuthenticated = localStorage.getItem("user") ? true : false;
	const loginOrProfile = isAuthenticated ? "profile" : "login";

	const handleSearchChange = (e) => {
		const term = e.target.value;
		setSearchTerm(term);

		const filteredProducts =
			allProducts &&
			allProducts.filter((product) => product.name.toLowerCase().includes(term.toLowerCase()));
		setSearchData(filteredProducts);
	};

	window.addEventListener("scroll", () => {
		if (window.scrollY > 70) {
			setActive(true);
		} else {
			setActive(false);
		}
	});

	return (
		<>
			<div className={`${styles.section}`}>
				<div className="hidden 800px:h-[50px] 800px:my-[20px] 800px:flex items-center justify-between">
					<div>
						<Link to="/">
							<img width={300} height={200} src={logo} alt="" />
						</Link>
					</div>
					{/* search box */}
					<div className="w-[50%] relative">
						<input
							type="text"
							placeholder="Search Product..."
							value={searchTerm}
							onChange={handleSearchChange}
							onFocus={() => setIsFocused(true)}
							className="h-[40px] w-full px-2 border-[#3957db] border-[2px] rounded-md"
						/>
						<AiOutlineSearch
							size={30}
							className="absolute right-2 top-1.5 cursor-pointer"
						/>
						{searchData && searchData.length !== 0 && isFocused && searchTerm ? (
							<div className="absolute min-h-[30vh] bg-slate-50 shadow-sm-2 z-[9] p-4">
								{searchData.map((i, index) => (
									<Link to={`/product/${i._id}`} key={i._id} target="_parent">
										<div className="w-full flex items-start-py-3" >
											<img
												src={`${i.images[0]?.url}`}
												alt=""
												className="w-[40px] h-[40px] mr-[10px]"
											/>
											<h1>{i.name}</h1>
										</div>
									</Link>
								))}
							</div>
						) : null}
					</div>

					<div className={`${styles.button}`}>
						<Link to={`${isSeller ? "/dashboard" : "/shop-create"}`}>
							<h1 className="text-[#fff] flex items-center">
								{isSeller ? "Go Dashboard" : "Become Seller"}{" "}
								<IoIosArrowForward className="ml-1" />
							</h1>
						</Link>
					</div>
				</div>
			</div>
			<div
				className={`${
					active === true ? "shadow-sm fixed top-0 left-0 z-10" : null
				} transition hidden 800px:flex items-center justify-between w-full bg-[#3321c8] h-[70px]`}
			>
				<div className={`${styles.section} relative ${styles.noramlFlex} justify-between`}>
					{/* categories */}
					<div onClick={() => setDropDown(!dropDown)}>
						<div className="relative h-[60px] mt-[10px] w-[270px] hidden 1000px:block">
							<BiMenuAltLeft size={30} className="absolute top-3 left-2" />
							<button
								className={`h-[100%] w-full flex justify-between items-center pl-10 bg-white font-sans text-lg font-[500] select-none rounded-t-md`}
							>
								All Categories
							</button>
							<IoIosArrowDown
								size={20}
								className="absolute right-2 top-4 cursor-pointer"
								onClick={() => setDropDown(!dropDown)}
							/>
							{dropDown ? (
								<DropDown
									categoriesData={categoriesData}
									setDropDown={setDropDown}
								/>
							) : null}
						</div>
					</div>
					{/* navitems */}
					<div className={`${styles.noramlFlex}`}>
						<Navbar active={activeHeading} />
					</div>

					<div className="flex">
						<div className={`${styles.noramlFlex}`}>
							<div
								className="relative cursor-pointer mr-[15px]"
								onClick={() => setOpenWishlist(true)}
							>
								<AiOutlineHeart
									size={30}
									color="rgb(255 255 255 / 83%)"
								/>
								<span className="absolute right-0 top-0 rounded-full bg-[#3bc177] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px] leading-tight text-center">
									{wishlist && wishlist.length}
								</span>
							</div>
						</div>

						<div className={`${styles.noramlFlex}`}>
							<div
								className="relative cursor-pointer mr-[15px]"
								onClick={() => setOpenCart(true)}
							>
								<AiOutlineShoppingCart
									size={30}
									color="rgb(255 255 255 / 83%)"
								/>
								<span className="absolute right-0 top-0 rounded-full bg-[#3bc177] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px] leading-tight text-center">
									{cart && cart.length}
								</span>
							</div>
						</div>

						<div className={`${styles.noramlFlex}`}>
							<div className="relative cursor-pointer mr-[15px]">
								{isAuthenticated ? (
									<Link to="/profile">
										<img
											src={`${user?.avatar?.url}`}
											className="w-[35px] h-[35px] rounded-full"
											alt=""
										/>
									</Link>
								) : (
									<Link to="/login">
										<CgProfile
											size={30}
											color="rgb(255 255 255 / 83%)"
										/>
									</Link>
								)}
							</div>
						</div>

						{/* cart popup */}
						{openCart ? <Cart setOpenCart={setOpenCart} /> : null}

						{/* wishlist popup */}
						{openWishlist ? <Wishlist setOpenWishlist={setOpenWishlist} /> : null}
					</div>
				</div>
			</div>

			{/* mobile header */}
			<div
				className={`${active === true ? "shadow-sm fixed top-0 left-0 z-10" : ""
					} w-full h-[80px] bg-[#fff] z-50 top-0 left-0 shadow-sm 800px:hidden`}
			>
				<div className="w-full flex justify-between items-center px-4">
					<div className="flex items-center">
						<BiMenuAltLeft
							size={40}
							className="mr-2"
							onClick={() => setOpen(true)}
						/>
						<Link to="/">
							<img
								width={200}
								height={150}
								src={logo}
								alt=""
								className="mt-2 cursor-pointer"
							/>
						</Link>
					</div>
					<div className="flex items-center">
						{/* login */}
						<div
							className="mr-1"
							onClick={() => (window.location.href = `/${loginOrProfile}`)}
						>
							<AiOutlineUser size={30} className="mt-3 cursor-pointer" />
							{loginOrProfile}
						</div>
						<div className="relative mr-0" onClick={() => setOpenCart(true)}>
							<AiOutlineShoppingCart size={30} />
							<span className="absolute right-0 top-0 transform translate-x-1/2 -translate-y-1/2 rounded-full bg-[#3bc177] w-4 h-4 text-white font-mono text-[12px] leading-tight text-center">
								{cart && cart.length}
							</span>
						</div>
					</div>
				</div>

				{/* cart popup */}
				{openCart ? <Cart setOpenCart={setOpenCart} /> : null}

				{/* wishlist popup */}
				{openWishlist ? <Wishlist setOpenWishlist={setOpenWishlist} /> : null}

				{/* header sidebar */}
				{open && (
					<div className="fixed w-full bg-[#0000005f] z-20 h-full top-0 left-0">
						<div className="fixed w-[70%] bg-[#fff] h-screen top-0 left-0 z-10 overflow-y-scroll">
							<div className="w-full flex justify-between pr-3">
								<div
									className="relative mr-[15px]"
									onClick={() =>
										setOpenWishlist(true) || setOpen(false)
									}
								>
									<AiOutlineHeart
										size={30}
										className="mt-5 ml-3"
									/>
									<span className="absolute right-0 top-0 rounded-full bg-[#3bc177] w-4 h-4 text-white font-mono text-[12px] leading-tight text-center">
										{wishlist && wishlist.length}
									</span>
								</div>
								<RxCross1
									size={30}
									className="mt-5 cursor-pointer"
									onClick={() => setOpen(false)}
								/>
							</div>

							<div className="my-8 w-[92%] m-auto relative">
								<input
									type="search"
									placeholder="Search Product..."
									className="h-[40px] w-full px-2 border-[#3957db] border-[2px] rounded-md"
									value={searchTerm}
									onChange={handleSearchChange}
								/>
								{searchData && (
									<div className="absolute bg-[#fff] z-10 shadow w-full left-0 p-3">
										{searchData.map((i) => (
											<Link
												to={`/product/${i._id}`}
												key={i._id}
											>
												<div className="flex items-center">
													<img
														src={
															i
																.images[0]
																?.url
														}
														alt=""
														className="w-[50px] mr-2"
													/>
													<h5>
														{i.name}
													</h5>
												</div>
											</Link>
										))}
									</div>
								)}
							</div>

							<Navbar active={activeHeading} />
							<div className={`${styles.button}`}>
								<Link to={`${isSeller ? "/dashboard" : "/shop-create"}`}>
									<h1 className="text-[#fff] flex items-center">
										{isSeller ? "Go Dashboard" : "Become Seller"}{" "}
										<IoIosArrowForward className="ml-1" />
									</h1>
								</Link>
							</div>

							<div className="flex w-full justify-center mt-4">
								{isAuthenticated ? (
									<Link to="/profile">
										<img
											src={user.avatar?.url}
											alt=""
											className="w-[60px] h-[60px] rounded-full border-[3px] border-[#0eae88]"
										/>
									</Link>
								) : (
									<>
										<Link
											to="/login"
											className="text-[18px] pr-[10px] text-[#000000b7]"
										>
											Login /
										</Link>
										<Link
											to="/sign-up"
											className="text-[18px] text-[#000000b7]"
										>
											Sign up
										</Link>
									</>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default Header;
