import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllProductsShop } from "../../redux/actions/product";
import { deleteProduct } from "../../redux/actions/product";
import Loader from "../Layout/Loader";
import axios from "axios";
import { server } from "../../server";
import { useState } from "react";

const AllProducts = () => {
	const [data, setData] = useState([]);
	const token = JSON.parse(localStorage.getItem("token"));

	useEffect(() => {
		axios.get(`${server}/product/get-products/admin`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}).then((res) => {
			setData(res.data.products);
		});
	}, []);

	const columns = [
		{ field: "id", headerName: "Product Id", minWidth: 150, flex: 0.7 },
		{
			field: "name",
			headerName: "Name",
			minWidth: 180,
			flex: 1.4,
		},
		{
			field: "price",
			headerName: "Price",
			minWidth: 100,
			flex: 0.6,
		},
		{
			field: "Stock",
			headerName: "Stock",
			type: "number",
			minWidth: 80,
			flex: 0.5,
		},

		{
			field: "sold",
			headerName: "Sold out",
			type: "number",
			minWidth: 130,
			flex: 0.6,
		},
		{
			field: "Preview",
			flex: 0.8,
			minWidth: 100,
			headerName: "",
			type: "number",
			sortable: false,
			renderCell: (params) => {
				return (
					<>
						<Link to={`/product/${params.id}`}>
							<Button>
								<AiOutlineEye size={20} />
							</Button>
						</Link>
					</>
				);
			},
		},
	];

	const row = [];

	data &&
		data.forEach((item) => {
			row.push({
				id: item._id,
				name: item.name,
				price: "₦" + item.discountPrice,
				Stock: item.stock,
				sold: item?.sold_out,
			});
		});

	return (
		<>
			<div className="w-full mx-8 pt-1 mt-10 bg-white">
				<DataGrid
					rows={row}
					columns={columns}
					pageSize={10}
					disableSelectionOnClick
					autoHeight
				/>
			</div>
		</>
	);
};

export default AllProducts;
