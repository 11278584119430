import { React, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { RxAvatar } from "react-icons/rx";

const ShopCreate = () => {
	const [email, setEmail] = useState("");
	const [name, setName] = useState(""); // Shop name
	const [sellerFullName, setSellerFullName] = useState("");
	const [phoneNumber, setPhoneNumber] = useState();
	const [university, setUniversity] = useState("");
	const [universities, setUniversities] = useState([]);
	const [idCard, setIdCard] = useState();
	const [address, setAddress] = useState("");
	const [avatar, setAvatar] = useState();
	const [password, setPassword] = useState("");
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		axios.post(`${server}/shop/create-shop`, {
			name,
			sellerFullName,
			email,
			password,
			avatar,
			// zipCode,
			university,
			vendorDocument: idCard,
			address,
			phoneNumber,
		})
			.then((res) => {
				toast.success(res.data.message);
				setName("");
				setSellerFullName("");
				setEmail("");
				setPassword("");
				setAvatar();
				// setZipCode();
				setAddress("");
				setUniversity("");
				setIdCard();
				setPhoneNumber();
			})
			.catch((error) => {
				toast.error(error.response.data.message);
				setLoading(false);
			});
	};

	const handleAvatarChange = (e) => {
		const reader = new FileReader();

		reader.onload = () => {
			if (reader.readyState === 2) {
				setAvatar(reader.result);
			}
		};

		reader.readAsDataURL(e.target.files[0]);
	};

	const handleIdCardChange = (e) => {
		const reader = new FileReader();

		reader.onload = () => {
			if (reader.readyState === 2) {
				setIdCard(reader.result);
			}
		};

		reader.readAsDataURL(e.target.files[0]);
	};

	useEffect(() => {
		const fetchUniversities = async () => {
			try {
				const response = await axios.get(`${server}/university/all`);
				setUniversities(response.data.universities);
			} catch (error) {
				console.error("Error fetching universities", error);
				toast.error("Error fetching universities");
			}
		};
	
		fetchUniversities();
	}, []);

	return (
		<div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
			{loading && (
				<div className="fixed inset-0 flex items-center justify-center bg-gray-50 bg-opacity-75 z-50">
					<div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-300"></div>
				</div>
			)}
			<div className="sm:mx-auto sm:w-full sm:max-w-md">
				<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
					Register as a seller
				</h2>
			</div>
			<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-[35rem]">
				<div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
					<form className="space-y-6" onSubmit={handleSubmit}>
						<div>
							<label
								htmlFor="sellername"
								className="block text-sm font-medium text-gray-700"
							>
								Full Name
							</label>
							<div className="mt-1">
								<input
									type="name"
									name="name"
									required
									value={sellerFullName}
									onChange={(e) =>
										setSellerFullName(e.target.value)
									}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
								/>
							</div>
						</div>
						<div>
							<label
								htmlFor="shopname"
								className="block text-sm font-medium text-gray-700"
							>
								Shop(or Brand) Name
							</label>
							<div className="mt-1">
								<input
									type="name"
									name="name"
									required
									value={name}
									onChange={(e) => setName(e.target.value)}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
								/>
							</div>
						</div>

						<div>
							<label
								htmlFor="number"
								className="block text-sm font-medium text-gray-700"
							>
								Phone Number
							</label>
							<div className="mt-1">
								<input
									type="number"
									name="phone-number"
									required
									value={phoneNumber}
									onChange={(e) => setPhoneNumber(e.target.value)}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
								/>
							</div>
						</div>

						<div>
							<label
								htmlFor="email"
								className="block text-sm font-medium text-gray-700"
							>
								Email address
							</label>
							<div className="mt-1">
								<input
									type="email"
									name="email"
									autoComplete="email"
									required
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
								/>
							</div>
						</div>

						<div>
							<label
								htmlFor="dropdown"
								className="block text-sm font-medium text-gray-700"
							>
								Choose University
							</label>
							<div className="mt-1 relative">
								<select
									name="university"
									value={university}
									onChange={(e) => setUniversity(e.target.value)}
									required
									className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
								>
									<option value="" disabled hidden>
									Select university
									</option>
									{universities && universities.map((university) => (
									<option key={university.id} value={university.name}>
										{university.name}
									</option>
									))}
								</select>
							</div>
						</div>

						<div>
							<label
								htmlFor="idCard"
								className="block text-sm font-medium text-gray-700"
							>
								Upload Image of School ID Card (or a valid student
								identification document image from your school)
							</label>
							<div className="mt-2 flex items-center">
								<label
									htmlFor="idCard-input"
									className="flex items-center justify-center w-full h-24 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
								>
									<span>
										{idCard ? (
											<img
												src={idCard}
												alt="idCard"
												className="h-24"
											/>
										) : (
											<p>Upload Image</p>
										)}
									</span>
									<input
										type="file"
										name="idCard"
										id="idCard-input"
										onChange={handleIdCardChange}
										className="sr-only"
										accept="image/*"
									/>
								</label>
							</div>
						</div>

						<div>
							<label
								htmlFor="email"
								className="block text-sm font-medium text-gray-700"
							>
								Address
							</label>
							<div className="mt-1">
								<input
									type="address"
									name="address"
									required
									value={address}
									onChange={(e) => setAddress(e.target.value)}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
								/>
							</div>
						</div>

						<div>
							<label
								htmlFor="password"
								className="block text-sm font-medium text-gray-700"
							>
								Password
							</label>
							<div className="mt-1 relative">
								<input
									type={visible ? "text" : "password"}
									name="password"
									autoComplete="current-password"
									required
									pattern=".{6,}"
									title="Password must be at least 6 characters long"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
								/>
								{visible ? (
									<AiOutlineEye
										className="absolute right-2 top-2 cursor-pointer"
										size={25}
										onClick={() => setVisible(false)}
									/>
								) : (
									<AiOutlineEyeInvisible
										className="absolute right-2 top-2 cursor-pointer"
										size={25}
										onClick={() => setVisible(true)}
									/>
								)}
							</div>
						</div>

						<div>
							<label
								htmlFor="avatar"
								className="block text-sm font-medium text-gray-700"
							>
								Brand logo (if any)
							</label>
							<div className="mt-2 flex items-center">
								<span className="inline-block h-8 w-8 rounded-full overflow-hidden">
									{avatar ? (
										<img
											src={avatar}
											alt="avatar"
											className="h-full w-full object-cover rounded-full"
										/>
									) : (
										<RxAvatar className="h-8 w-8" />
									)}
								</span>
								<label
									htmlFor="file-input"
									className="ml-5 flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
								>
									<span>Upload a file</span>
									<input
										type="file"
										name="avatar"
										id="file-input"
										onChange={handleAvatarChange}
										className="sr-only"
									/>
								</label>
							</div>
						</div>

						<div>
							<button
								type="submit"
								className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
								disabled={loading}
							>
								Submit
							</button>
						</div>
						<div className={`${styles.noramlFlex} w-full`}>
							<h4>Already have an account?</h4>
							<Link to="/shop-login" className="text-blue-600 pl-2">
								Sign in
							</Link>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ShopCreate;
