import React from "react"
import { useSelector } from "react-redux"
import EventCard from "../components/Events/EventCard"
import Header from "../components/Layout/Header"
import Footer from "../components/Layout/Footer"
import Loader from "../components/Layout/Loader"

const EventsPage = () => {
	const { allEvents, isLoading } = useSelector((state) => state.events)
	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<div>
					<Header activeHeading={4} />
					{allEvents.length === 0 ? (
						<div className="flex items-center justify-center min-h-screen">
							<div className="text-center">
								<h1 className="text-4xl font-bold mb-4">No Events!</h1>
								<p className="text-lg">
									Whoops! There are no running events
									<br />
								</p>
							</div>
						</div>
					) : (
						<div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-4 xl:gap-[200px] mb-12">
							{allEvents.length !== 0 && (
								allEvents.map((i, index) => (
									<EventCard data={i} key={index} />
								))
							)}
						</div>
					)}
					<Footer/>
				</div>
			)}
		</>
	)
}

export default EventsPage
