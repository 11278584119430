import React, { useState } from "react";
import Header from "../components/Layout/Header";
import styles from "../styles/styles";
import Loader from "../components/Layout/Loader";
import ProfileSideBar from "../components/Profile/ProfileSidebar";
import ProfileContent from "../components/Profile/ProfileContent";
import { useSelector } from "react-redux";
import { BsBoxArrowLeft, BsBoxArrowRight } from "react-icons/bs";

const ProfilePage = () => {
	const { loading } = useSelector((state) => state.user);
	const [active, setActive] = useState(1);
	const [showNavName, setShowNavName] = useState(false);

	return (
		<div>
			{loading ? (
				<Loader />
			) : (
				<>
					<Header />
					<div className={`${styles.section} flex bg-[#f5f5f5] py-10 overflow-hidden`}>
						{/* TOGGLE ICON */}
						<div
							onClick={() => setShowNavName(!showNavName)}
							className="absolute left-0 bg-white flex items-center top-20 p-4 shadow text-xl 800px:hidden z-10"
						>
							{!showNavName ? <BsBoxArrowRight /> : <BsBoxArrowLeft />}
						</div>
						{/* TOGGLE ICON */}

						{/* OVAERLAY */}
						{showNavName && (
							<div
								className="w-full h-screen bg-black/20 absolute  top-20 left-0"
								onClick={() => setShowNavName(false)}
							/>
						)}
						{/* OVAERLAY */}

						<div
							onClick={() => setShowNavName(false)}
							className={` absolute 800px:mt-0 mt-[3.5rem] 800px:w-[335px]  ${
								showNavName ? "w-[335px]  " : "w-[50px] "
							}`}
						>
							<ProfileSideBar
								active={active}
								showNavName={showNavName}
								setActive={setActive}
							/>
						</div>
						<ProfileContent active={active} />
					</div>
				</>
			)}
		</div>
	);
};

export default ProfilePage;
