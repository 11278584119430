import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import styles from "../styles/styles";
import Pagination from "@mui/material/Pagination";
import { server } from "../server";
import axios from "axios";

const ProductsPage = () => {
	const [searchParams] = useSearchParams();
	const categoryData = searchParams.get("category");
	const { allProducts, isLoading } = useSelector((state) => state.products);
	const { user, isAuthenticated } = useSelector((state) => state.user);
	const [data, setData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const productsPerPage = 20;

	useEffect(() => {
		async function fetchProduct() {
			if (categoryData === null) {
				const d = allProducts;
				setData(d);
			} else {
				const d = await axios.get(`${server}/product/get-all-products?sch=${user.university}&category=${categoryData}`)
				setData(d.data.products);
			}

		}
		fetchProduct();
		window.scrollTo(0, 0);
	}, [allProducts, categoryData]);

	// Pagination logic
	const indexOfLastProduct = currentPage * productsPerPage;
	const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
	const currentProducts = data && data?.slice(indexOfFirstProduct, indexOfLastProduct);

	const handlePageChange = (event, value) => {
		setCurrentPage(value);
		window.scrollTo(0, 0);
	};

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<div>
					<Header activeHeading={3} />
					<br />
					<br />
					<div className={`${styles.section}`}>
						<div className="grid grid-cols-2 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
							{currentProducts &&
								currentProducts?.map((i, index) => (
									<ProductCard data={i} key={index} />
								))}
						</div>
						{!isAuthenticated && data && data.length === 0? (
							<h1 className="text-center w-full pb-[100px] text-[20px]">
								{`Login to see ${categoryData} from your university.`}
							</h1>
						) : (data && data.length === 0) ? (
							<h1 className="text-center w-full pb-[100px] text-[20px]">
								No products found!
							</h1>
						) : null}
						<div className="flex justify-center mt-4 mb-2">
							<Pagination
								count={Math.ceil(data?.length / productsPerPage)}
								page={currentPage}
								onChange={handlePageChange}
								variant="outlined"
								shape="rounded"
							/>
						</div>
					</div>
					<Footer />
				</div>
			)}
		</>
	);
};

export default ProductsPage;
