import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { AiOutlineEye } from "react-icons/ai";
import { Button, MenuItem, Select } from "@mui/material";
import styles from "../../styles/styles";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";

const PendingVerification = () => {
	const [sellers, setSellers] = useState([]);
	const [open, setOpen] = useState(false);
	const [document, setDocument] = useState("");
	const [userId, setUserId] = useState("");
	const token = JSON.parse(localStorage.getItem("token"));

	useEffect(() => {
		const fetchUnverifiedSellers = async () => {
			try {
				const response = await axios.get(`${server}/shop/admin-all-unverified-sellers`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				setSellers(response.data?.unverifiedSellers);
			} catch (error) {
				toast.error("Failed to fetch unverified sellers");
			}
		};
		fetchUnverifiedSellers();
	}, [token]);

	const handleStatusChange = async (id, status) => {
		try {
			const response = await axios.put(
				`${server}/shop/admin-update-seller-status/${id}`,
				{ status },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			toast.success(response.data.message);
			// Refresh the list of unverified sellers after status change
			const refreshedSellers = await axios.get(`${server}/shop/admin-all-unverified-sellerss`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			setSellers(refreshedSellers.data);
		} catch (error) {
			toast.error(error.response.data.message);
		}
	};

	const columns = [
		{ field: "id", headerName: "Seller ID", minWidth: 50, flex: 0.7 },
		{ field: "university", headerName: "University", minWidth: 130, flex: 0.7 },
		{
			field: "fullName",
			headerName: "Full Name",
			minWidth: 160,
			flex: 0.7,
		},
		{
			field: "email",
			headerName: "Email",
			type: "text",
			minWidth: 130,
			flex: 0.7,
		},
		{
			field: "address",
			headerName: "Seller Address",
			type: "text",
			minWidth: 130,
			flex: 0.7,
		},
		{
			field: "document",
			flex: 0.8,
			minWidth: 50,
			headerName: "Preview Document",
			sortable: false,
			renderCell: (params) => {
				return (
					<>
						<Button
							onClick={() =>
								setDocument(params.row.document) || setOpen(true)
							}
						>
							<AiOutlineEye size={20} />
						</Button>
					</>
				);
			},
		},
		{
			field: "status",
			flex: 1,
			minWidth: 150,
			headerName: "Edit Status",
			sortable: false,
			renderCell: (params) => {
				return (
					<Select
						value={params.row.status || "unverified"}
						onChange={(e) => handleStatusChange(params.id, e.target.value)}
					>
						<MenuItem value="verified">Verified</MenuItem>
						<MenuItem value="failed">Failed</MenuItem>
						<MenuItem value="unverified">Unverified</MenuItem>
					</Select>
				);
			},
		},
	];

	const row = [];
	sellers &&
		sellers.forEach((item) => {
			row.push({
				id: item._id,
				university: item.university,
				fullName: item?.fullName,
				email: item?.email,
				address: item.address,
				document: item.vendorDocument?.url,
				status: item.status,
			});
		});

	return (
		<div className="w-full flex justify-center pt-5">
			<div className="w-[97%]">
				<h3 className="text-[22px] font-Poppins pb-2">Pending Verification</h3>
				<div className="w-full min-h-[45vh] bg-white rounded">
					<DataGrid
						rows={row}
						columns={columns}
						pageSize={10}
						disableSelectionOnClick
						autoHeight
					/>
				</div>
				{open && (
					<div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
						<div className="w-[95%] 800px:w-[40%] min-h-[20vh] bg-white rounded shadow p-5">
							<div className="w-full flex justify-end cursor-pointer">
								<RxCross1 size={25} onClick={() => setOpen(false)} />
							</div>
							<h3 className="text-[25px] text-center py-5 font-Poppins text-[#000000cb]">
								Document Preview
							</h3>
							<div className="w-full flex items-center justify-center">
								<iframe
									src={document}
									width="100%"
									height="500px"
									title="Document Preview"
								/>
							</div>
							<div className="w-full flex justify-center pt-5">
								<div
									className={`${styles.button} text-white text-[18px] !h-[42px]`}
									onClick={() => setOpen(false)}
								>
									Close
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default PendingVerification;
