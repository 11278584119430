import React, { useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { server } from "../server";

const ResetPassword = () => {
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const { token } = useParams();
	const isSeller = new URLSearchParams(location.search).get("isSeller");

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (password !== confirmPassword) {
			toast.error("Passwords do not match");
			return;
		}

		setLoading(true);

		const endpoint = (isSeller ? "shop" : "user") + "/reset-password";

		const loginRoute = isSeller ? "/shop-login" : "/login";
		try {
			const res = await axios.post(`${server}/${endpoint}`, {
				resetPasswordToken: token,
				password,
				confirmPassword,
			});

			toast.success(res.data.message);
			navigate(loginRoute);
		} catch (err) {
			toast.error(err.response?.data.message);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
			{loading && (
				<div className="fixed inset-0 flex items-center justify-center bg-gray-50 bg-opacity-75 z-50">
					<div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-300"></div>
				</div>
			)}
			<div className="sm:mx-auto sm:w-full sm:max-w-md">
				<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
					Set a new password
				</h2>
			</div>
			<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
				<div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
					<form className="space-y-6" onSubmit={handleSubmit}>
						<div>
							<label
								htmlFor="password"
								className="block text-sm font-medium text-gray-700"
							>
								New Password
							</label>
							<div className="mt-1">
								<input
									type="password"
									name="password"
									autoComplete="new-password"
									required
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
								/>
							</div>
						</div>
						<div>
							<label
								htmlFor="confirm-password"
								className="block text-sm font-medium text-gray-700"
							>
								Confirm New Password
							</label>
							<div className="mt-1">
								<input
									type="password"
									name="confirm-password"
									autoComplete="new-password"
									required
									value={confirmPassword}
									onChange={(e) =>
										setConfirmPassword(e.target.value)
									}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
								/>
							</div>
						</div>
						<div>
							<button
								type="submit"
								className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
								disabled={loading}
							>
								Reset Password
							</button>
						</div>
						<div className="text-sm text-center">
							<Link
								to="/login"
								className="font-medium text-blue-600 hover:text-blue-500"
							>
								Back to Login
							</Link>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
