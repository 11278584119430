import React, { useState } from "react";
import styles from "../../styles/styles";
import CountDown from "./CountDown";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addTocart } from "../../redux/actions/cart";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";


const EventCard = ({ active, data }) => {
  const { cart } = useSelector((state) => state.cart);
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const description = data.description;
  const toggleReadMore = () => {
    navigate(`/events/${data.customName}`)
  };

  const MAX_LENGTH = 50;

  const addToCartHandler = (data) => {
    const isItemExists = cart && cart.find((i) => i._id === data._id);
    const isTicketExists = cart && cart.find((i) => i.category === "Tickets");

    if (isItemExists) {
      toast.error("Item already in cart!");
    } else if (isTicketExists) {
      toast.error("Max of 1 ticket at a go!");
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  return (
    <div className={`block bg-white rounded-lg ${active ? "unset" : "mb-12"} p-2`} style={{ width: '300px', margin: '10px' }}>
      <div className="w-full" style={{ height: '150px', overflow: 'hidden' }}>
        <div className="flex space-x-2 h-full">
          {data?.images?.map((image, index) => (
            <img
              key={index}
              src={image.url}
              alt=""
              style={{ minWidth: '100%', height: '100%', objectFit: 'cover' }}
            />
          ))}
        </div>
      </div>
      <div className="flex flex-col justify-center mt-2">
        <h2 className={`${styles.productTitle}`} style={{ fontSize: '16px' }}>{data.name}</h2>
        <>
          <p style={{ fontSize: '14px' }}>
            {(!isExpanded || description.length >= MAX_LENGTH) && `${description.substring(0, MAX_LENGTH)}...`}
          </p>
          {description.length > MAX_LENGTH && (
            <button onClick={toggleReadMore} style={{ color: 'blue', cursor: 'pointer', background: 'none', border: 'none', padding: 0 }}>
              {isExpanded ? 'Read less' : 'Read more'}
            </button>
          )}
        </>
        <div className="flex py-2 justify-between">
          <div className="flex">
            {
              data.originalPrice && (
                <h5 className="font-[500] text-[14px] text-[#d55b45] pr-3 line-through">
                  ₦{data.originalPrice}
                </h5> 
              )
            }
            <h5 className="font-bold text-[16px] text-[#333] font-Roboto">
              ₦{data.discountPrice}
            </h5>
          </div>
          {/* <span className="pr-3 font-[400] text-[14px] text-[#44a55e]">
            {data.sold_out} sold
          </span> */}
        </div>
        <CountDown data={data} />
        <div className="flex items-center mt-2">
          {data.category === "Tickets" ? (
            <>
              <Link to={`/events/${data.customName}`}>
                <div className={`${styles.button} text-[#fff]`} style={{ fontSize: '14px', padding: '5px 10px' }}>See Details</div>
              </Link>
              <div className={`${styles.button} text-[#fff] ml-2`} style={{ fontSize: '14px', padding: '5px 10px' }} onClick={() => addToCartHandler(data)}>Get Ticket</div>
            </>
          ) : (
            <>
              <Link to={`/product/${data._id}?isEvent=true`}>
                <div className={`${styles.button} text-[#fff]`} style={{ fontSize: '14px', padding: '5px 10px' }}>See Details</div>
              </Link>
              <div className={`${styles.button} text-[#fff] ml-2`} style={{ fontSize: '14px', padding: '5px 10px' }} onClick={() => addToCartHandler(data)}>Add to cart</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventCard;
