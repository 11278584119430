import React, { useState } from "react";

import { BsBoxArrowLeft, BsBoxArrowRight } from "react-icons/bs";
import Footer from "../../components/Layout/Footer";
import ShopSettings from "../../components/Shop/ShopSettings";
import DashboardHeader from "../../components/Shop/Layout/DashboardHeader";
import DashboardSideBar from "../../components/Shop/Layout/DashboardSideBar";

const ShopSettingsPage = () => {
	const [showNavName, setShowNavName] = useState(false);

	return (
		<div>
			<DashboardHeader />
			<div className="flex items-start justify-between w-full">
				{/* TOGGLE ICON */}
				<div
					onClick={() => setShowNavName(!showNavName)}
					className="absolute left-0 bg-white flex items-center top-20 p-4 shadow text-xl 800px:hidden z-10"
				>
					{!showNavName ? <BsBoxArrowRight /> : <BsBoxArrowLeft />}
				</div>
				{/* TOGGLE ICON */}

				{/* OVAERLAY */}
				{showNavName && (
					<div
						className="w-full h-full bg-black/20 absolute  top-20 left-0"
						onClick={() => setShowNavName(false)}
					/>
				)}
				{/* OVAERLAY */}

				<div
					onClick={() => setShowNavName(false)}
					className={` absolute 800px:sticky  800px:mt-0 mt-[3.5rem] 800px:w-[335px]  ${
						showNavName ? "w-[335px]  " : "w-[50px] "
					}`}
				>
					<DashboardSideBar active={11} showNavName={showNavName} />
				</div>
				<ShopSettings />
			</div>
		</div>
	);
};

export default ShopSettingsPage;
