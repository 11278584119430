import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";
import EventCard from "./EventCard";

const Events = () => {
	const { allEvents, isLoading } = useSelector((state) => state.events);
	const scrollRef = useRef(null);

	return (
		<div>
			{!isLoading && allEvents?.length !== 0 && (
				<div className={`${styles.section}`}>
					<div className={`${styles.heading}`}>
						<h1>Popular Events</h1>
					</div>
					<div className="relative">
						<div
							ref={scrollRef}
							className="flex overflow-x-scroll gap-[20px] md:gap-[25px] lg:gap-[25px] xl:gap-[30px] mb-12"
							style={{
								scrollbarWidth: "none", /* Firefox */
								msOverflowStyle: "none", /* IE and Edge */
							}}
						>
							<style>
								{`.flex::-webkit-scrollbar {
									display: none;
								}`
								}
							</style>
							{allEvents.slice(0, 5).map((i, index) => (
								<EventCard data={i} key={index} />
							))}
						</div>
						<Link to={`/events`}>
							<div
								className={`${styles.button} text-[#fff] mt-4`}
								style={{ fontSize: "14px", padding: "5px 10px" }}
							>
								View all Events
							</div>
						</Link>
					</div>
				</div>
			)}
		</div>
	);
};

export default Events;
