import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { server } from "../../server";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { updateProduct } from "../../redux/actions/product";

const UpdateProduct = () => {
	const { id } = useParams(); // Get the product id from the route params
    const navigate = useNavigate();
	const [product, setProduct] = useState({});
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [originalPrice, setOriginalPrice] = useState("");
	const [discountPrice, setDiscountPrice] = useState("");
	const [stock, setStock] = useState("");
	const [images, setImages] = useState([]);
	const [video, setVideo] = useState(null);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		const fetchProduct = async () => {
			try {
				const { data } = await axios.get(`${server}/product/${id}`);
				setProduct(data.product);
				setName(data.product.name);
				setDescription(data.product.description);
				setOriginalPrice(data.product.originalPrice);
				setDiscountPrice(data.product.discountPrice);
				setStock(data.product.stock);
				// setImages(data.product.images);
				// setVideo(data.product.video);
			} catch (error) {
				toast.error("Failed to load product data");
			}
		};

		fetchProduct();
	}, [id]);

	const handleImageChange = (e) => {
		const files = Array.from(e.target.files);
		setImages([]);

		files.forEach((file) => {
			const reader = new FileReader();

			reader.onload = () => {
				if (reader.readyState === 2) {
					setImages((old) => [...old, reader.result]);
				}
			};
			reader.readAsDataURL(file);
		});
	};

	const handleVideoChange = (e) => {
		const file = e.target.files[0];
		setVideo(""); // Clearing previous video
		const reader = new FileReader();

		reader.onload = () => {
			if (reader.readyState === 2) {
				setVideo(reader.result);
			}
		};

		if (file) {
			reader.readAsDataURL(file);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			const formData = new FormData();
			formData.append("name", name);
			formData.append("description", description);
			formData.append("originalPrice", originalPrice);
			formData.append("discountPrice", discountPrice);
			formData.append("stock", stock);

			images.forEach((image) => {
				formData.append("images", image);
			});

			if (video) {
				formData.append("video", video);
			}

			dispatch(
				updateProduct({
					id,
					name,
					description,
					// category,
					// tags,
					originalPrice,
					discountPrice,
					stock,
					images,
					video,
				})
			)

			toast.success("Product updated successfully!");
			navigate("/dashboard-products");
			window.location.reload();
		} catch (error) {
			toast.error("Failed to update product");
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="w-[90%] 800px:w-[50%] bg-white shadow h-[80vh] rounded-[4px] p-3 overflow-y-scroll">
			{loading && (
				<div className="fixed inset-0 flex items-center justify-center bg-gray-50 bg-opacity-75 z-50">
					<div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-300"></div>
				</div>
			)}
			<h5 className="text-[30px] font-Poppins text-center">Update Product</h5>
			{/* update product form */}
			<form onSubmit={handleSubmit}>
				<br />
				<div>
					<label className="pb-2">
						Name <span className="text-red-500">*</span>
					</label>
					<input
						type="text"
						name="name"
						value={name}
						className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
						onChange={(e) => setName(e.target.value)}
						placeholder="Enter your product name..."
					/>
				</div>
				<br />
				<div>
					<label className="pb-2">
						Description <span className="text-red-500">*</span>
					</label>
					<textarea
						cols="30"
						required
						rows="8"
						type="text"
						name="description"
						value={description}
						className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
						onChange={(e) => setDescription(e.target.value)}
						placeholder="Enter your product description..."
					></textarea>
				</div>
				<br />
				<div>
					<label className="pb-2">
						Original Price
					</label>
					<input
						type="number"
						name="originalPrice"
						value={originalPrice}
						className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
						onChange={(e) => setOriginalPrice(e.target.value)}
						placeholder="Enter your product original price..."
					/>
				</div>
				<br />
				<div>
					<label className="pb-2">
						Discount Price <span className="text-red-500">*</span>
					</label>
					<input
						type="number"
						name="discountPrice"
						value={discountPrice}
						className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
						onChange={(e) => setDiscountPrice(e.target.value)}
						placeholder="Enter your product discount price..."
					/>
				</div>
				<br />
				<div>
					<label className="pb-2">
						Product Stock <span className="text-red-500">*</span>
					</label>
					<input
						type="number"
						name="stock"
						value={stock}
						className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
						onChange={(e) => setStock(e.target.value)}
						placeholder="Enter your product stock..."
					/>
				</div>
				<br />
				<div>
					<label className="pb-2">
						Upload Images (You can select multiple images)
						<span className="text-red-500">*</span>
					</label>
					<input
						type="file"
						name=""
						id="upload"
						className="hidden"
						multiple
						onChange={handleImageChange}
					/>
					<div className="w-full flex items-center flex-wrap">
						<label htmlFor="upload">
							<AiOutlinePlusCircle size={30} className="mt-3" color="#555" />
						</label>
						{images &&
							images.map((i) => (
								<img
									src={i}
									key={i}
									alt=""
									className="h-[120px] w-[120px] object-cover m-2"
								/>
							))}
					</div>
				</div>
				<br />
				<div>
					<label className="pb-2">Upload Video</label>
					<input
						type="file"
						name=""
						id="upload1"
						className="hidden"
						onChange={handleVideoChange}
					/>
					<div className="w-full flex items-center flex-wrap">
						<label htmlFor="upload1">
							<AiOutlinePlusCircle size={30} className="mt-3" color="#555" />
						</label>
						{video && (
							<video
								width="320"
								height="240"
								controls
								className="h-[210px] w-[280px] object-cover m-3"
							>
								<source src={video} />
								Your browser does not support the video tag.
							</video>
						)}
					</div>
				</div>
				<br />
				<div>
					<input
						type="submit"
						value="Update"
						disabled={loading}
						className="mt-2 cursor-pointer appearance-none text-center block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
					/>
				</div>
			</form>
		</div>
	);
};

export default UpdateProduct;
