import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";

const NotFoundPage = () => {
  return (
    <div className="flex flex-col">
      <Header />

      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <h1 className="text-4xl font-bold mb-4">404 - Page Not Found</h1>
          <p className="text-lg">
            The page you are looking for does not exist. Try searching or go to{" "} <br />
            <a href="/" className="text-blue-500 hover:underline">
              Starkmarts' home page.
            </a>
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default NotFoundPage;
