import React, { useEffect, useState } from "react";
import { BsFillBagFill } from "react-icons/bs";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "../styles/styles";
import { getAllOrdersOfUser } from "../redux/actions/order";
import { server } from "../server";
import { RxCross1 } from "react-icons/rx";
import { AiFillStar, AiOutlineStar, AiOutlineMessage, } from "react-icons/ai";
import axios from "axios";
import { toast } from "react-toastify";

const UserOrderDetails = () => {
	const { orders } = useSelector((state) => state.order);
	const { user, isAuthenticated } = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [comment, setComment] = useState("");
	const [selectedItem, setSelectedItem] = useState(null);
	const [rating, setRating] = useState(1);
	const [selectedStatus, setSelectedStatus] = useState("");
	const [isStatusDisabled, setIsStatusDisabled] = useState(false);

	const { id } = useParams();
	const token = JSON.parse(localStorage.getItem("token"));

	useEffect(() => {
		dispatch(getAllOrdersOfUser(user._id));
	}, [dispatch, user._id]);

	const data = orders && orders.find((item) => item._id === id);

	const handleMessageSubmit = async () => {
		if (isAuthenticated) {
			const userId = user?._id;
			const sellerId = data?.cart[0]?.shopId;
			const groupTitle = userId + sellerId;
			await axios
				.post(`${server}/conversation/create-new-conversation`, {
					groupTitle,
					userId,
					sellerId,
				})
				.then((res) => {
					navigate(`/inbox?${res.data.conversation._id}`);
				})
				.catch((error) => {
					toast.error(error.response.data.message);
				});
		} else {
			toast.error("Please login to create a conversation");
		}
	};

	const reviewHandler = async (e) => {
		await axios
			.put(
				`${server}/product/create-new-review`,
				{
					user,
					rating,
					comment,
					productId: selectedItem?._id,
					orderId: id,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((res) => {
				toast.success(res.data.message);
				dispatch(getAllOrdersOfUser(user._id));
				setComment("");
				setRating(null);
				setOpen(false);
			})
			.catch((error) => {
				toast.error(error.message);
			});
	};

	const refundHandler = async () => {
		await axios
			.put(`${server}/order/order-refund/${id}`, {
				status: "Processing refund",
			})
			.then((res) => {
				toast.success(res.data.message);
				dispatch(getAllOrdersOfUser(user._id));
			})
			.catch((error) => {
				toast.error(error.response.data.message);
			});
	};

	const handleStatusChange = async (status) => {
		setSelectedStatus(status);

		if (status === "Satisfied") {
			toast.success("Thank you for shopping with us");
			setIsStatusDisabled(true);
			return;
		}

		if (status === "Not Satisfied") {
			return alert("Please message the vendor or Request a refund");
		}

		await axios
			.put(
				`${server}/order/buyer-status/${id}`,
				{
					status,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((res) => {
				toast.success(res.data.message);
				dispatch(getAllOrdersOfUser(user._id));
			})
			.catch((error) => {
				toast.error(error.response.data.message);
			});
	};

	return (
		<div className={`py-4 min-h-screen ${styles.section}`}>
			<div className="w-full flex items-center justify-between">
				<div className="flex items-center">
					<BsFillBagFill size={30} color="crimson" />
					<h1 className="pl-2 text-[25px]">Order Details</h1>
				</div>
			</div>

			<div className="w-full flex items-center justify-between pt-6">
				<h5 className="text-[#00000084]">
					Order ID: <span>#{data?._id?.slice(0, 8)}</span>
				</h5>
				<h5 className="text-[#00000084]">
					Placed on: <span>{data?.createdAt?.slice(0, 10)}</span>
				</h5>
			</div>

			<br />
			<br />
			{data &&
				data?.cart.map((item, index) => (
					<div key={index} className="w-full flex items-start mb-5">
						<img
							src={`${item.images[0]?.url}`}
							alt=""
							className="w-[80px] h-[80px]"
						/>
						<div className="w-full">
							<h5 className="pl-3 text-[20px]">{item.name}</h5>
							<h5 className="pl-3 text-[20px] text-[#00000091]">
								₦{item.discountPrice} x {item.qty}
							</h5>
						</div>
						{!item.isReviewed && data?.status === "Delivered" ? (
							<div
								className={`${styles.button} text-[#fff]`}
								onClick={() => {
									setOpen(true) || setSelectedItem(item);
								}}
							>
								Write a review
							</div>
						) : null}
					</div>
				))}

			{/* review popup */}
			{open && (
				<div className="w-full fixed top-0 left-0 h-screen bg-[#0005] z-50 flex items-center justify-center">
					<div className="w-[50%] h-min bg-[#fff] shadow rounded-md p-3">
						<div className="w-full flex justify-end p-3">
							<RxCross1
								size={30}
								onClick={() => setOpen(false)}
								className="cursor-pointer"
							/>
						</div>
						<h2 className="text-[30px] font-[500] font-Poppins text-center">
							Give a Review
						</h2>
						<br />
						<div className="w-full flex">
							<img
								src={`${selectedItem?.images[0]?.url}`}
								alt=""
								className="w-[80px] h-[80px]"
							/>
							<div>
								<div className="pl-3 text-[20px]">
									{selectedItem?.name}
								</div>
								<h4 className="pl-3 text-[20px]">
									NGN{selectedItem?.discountPrice} x{" "}
									{selectedItem?.qty}
								</h4>
							</div>
						</div>

						<br />
						<br />

						<h5 className="pl-3 text-[20px] font-[500]">
							Give a Rating <span className="text-red-500">*</span>
						</h5>
						<div className="flex w-full ml-2 pt-1">
							{[1, 2, 3, 4, 5].map((i) =>
								rating >= i ? (
									<AiFillStar
										key={i}
										className="mr-1 cursor-pointer"
										color="rgb(246,186,0)"
										size={25}
										onClick={() => setRating(i)}
									/>
								) : (
									<AiOutlineStar
										key={i}
										className="mr-1 cursor-pointer"
										color="rgb(246,186,0)"
										size={25}
										onClick={() => setRating(i)}
									/>
								)
							)}
						</div>
						<br />
						<div className="w-full ml-3">
							<label className="block text-[20px] font-[500]">
								Write a comment
								<span className="ml-1 font-[400] text-[16px] text-[#00000052]">
									(optional)
								</span>
							</label>
							<textarea
								name="comment"
								cols="20"
								rows="5"
								value={comment}
								onChange={(e) => setComment(e.target.value)}
								placeholder="How was your product? Write your expression about it!"
								className="mt-2 w-[95%] border p-2 outline-none"
							></textarea>
						</div>
						<div
							className={`${styles.button} text-white text-[20px] ml-3`}
							onClick={rating > 1 ? reviewHandler : null}
						>
							Submit
						</div>
					</div>
				</div>
			)}

			<div className="border-t w-full text-right">
				<h5 className="pt-3 text-[18px]">
					Total Price: <strong>₦{data?.totalPrice}</strong>
				</h5>
			</div>
			<br />
			<br />
			<div className="w-full 800px:flex items-center">
				<div className="w-full 800px:w-[60%]">
					<h4 className="pt-3 text-[20px] font-[600]">Shipping Address:</h4>
					<h4 className="pt-3 text-[20px]">{data?.shippingAddress.address1}</h4>
					<h4 className="text-[20px]">{data?.shippingAddress.country}</h4>
					<h4 className="text-[20px]">{data?.shippingAddress.city}</h4>
					<h4 className="text-[20px]">{data?.user?.phoneNumber}</h4>
				</div>
				<div className="w-full 800px:w-[40%]">
					<h4 className="pt-3 text-[20px]">Payment Info:</h4>
					<h4>
						Status:{" "}
						{data?.paymentInfo?.status ? data?.paymentInfo?.status : "Not Paid"}
					</h4>
					<br />
					{data?.status === "Delivered" && (
						<div className="w-full">
							<select
								className="w-full border p-2"
								value={selectedStatus}
								onChange={(e) => handleStatusChange(e.target.value)}
								disabled={isStatusDisabled}
							>
								<option value="">Select Status</option>
								{/* <option value="Received">Received</option> */}
								<option value="Satisfied">Satisfied</option>
								<option value="Not Satisfied">Not Satisfied</option>
							</select>
						</div>
					)}

					{selectedStatus === "Not Satisfied" && (
						<div
							className={`${styles.button} text-white mt-5 `}
							onClick={refundHandler}
							disabled={selectedStatus === "Satisfied"}
						>
							Request Refund
						</div>
					)}
				</div>
			</div>
			<br />
			<div
				className={`${styles.button} bg-[#000] mt-4 rounded-[4px] h-11`}
				onClick={handleMessageSubmit}
			>
				<span className="text-[#fff] flex items-center">
					Send Message <AiOutlineMessage className="ml-1" />
				</span>
			</div>
			<br />
			<br />
		</div>
	);
};

export default UserOrderDetails;
