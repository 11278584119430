import React, { useEffect, useState } from "react";
import { AiOutlineArrowRight, AiOutlineMoneyCollect, AiOutlineCopy } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link } from "react-router-dom";
import { MdBorderClear } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import { getAllProductsShop } from "../../redux/actions/product";
import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { server } from "../../server";
import axios from "axios";

const DashboardHero = () => {
	const dispatch = useDispatch();
	const { orders } = useSelector((state) => state.order);
	const { seller } = useSelector((state) => state.seller);
	const { products } = useSelector((state) => state.products);
	const [shopLink, setShopLink] = useState("");

	useEffect(() => {
		dispatch(getAllOrdersOfShop(seller._id));
		dispatch(getAllProductsShop(seller._id));
	}, [dispatch, seller._id]);

	const availableBalance = seller?.availableBalance.toFixed(2);

	useEffect(() => {
		fetchShopLink();
	});

	const fetchShopLink = async () => {
		try {
			const response = await axios.get(`${server}/shop/getCustomName?name=${seller.name}`);

			const customName = response.data.customName;

			const baseUrl = process.env.REACT_APP_ENV !== "development" ? "https://starkmarts.com.ng" : "http://localhost:3000";

			if (customName) {
				setShopLink(`${baseUrl}/shops/${customName}`);
			} else {
				setShopLink("");
			}

		} catch (error) {
			console.error("Error fetching shop link:", error);
		}
	};

	const copyShopLinkToClipboard = async () => {
		await navigator.clipboard.writeText(shopLink)
		alert("Shop link copied to clipboard!");
	};

	const columns = [
		{ field: "id", headerName: "Order ID", minWidth: 150, flex: 0.7 },
		{
			field: "status",
			headerName: "Status",
			minWidth: 130,
			flex: 0.7,
			cellClassName: (params) => {
				return params.getValue(params.id, "status") === "Delivered" ? "greenColor" : "redColor";
			},
		},
		{
			field: "itemsQty",
			headerName: "Items Qty",
			type: "number",
			minWidth: 130,
			flex: 0.7,
		},
		{
			field: "total",
			headerName: "Total",
			type: "number",
			minWidth: 130,
			flex: 0.8,
		},
		{
			field: " ",
			flex: 1,
			minWidth: 150,
			headerName: "",
			type: "number",
			sortable: false,
			renderCell: (params) => {
				return (
					<Link to={`/order/${params.id}`}>
						<Button>
							<AiOutlineArrowRight size={20} />
						</Button>
					</Link>
				);
			},
		},
	];

	const row = [];

	orders &&
		orders.forEach((item) => {
			row.push({
				id: item._id,
				itemsQty: item.cart.reduce((acc, item) => acc + item.qty, 0),
				total: "₦ " + item.totalPrice,
				status: item.status,
			});
		});

	return (
		<div className="w-full p-8 ml-10 800px:ml-0">
			<h3 className="text-[22px] font-Poppins pb-2">Overview</h3>
			<div className="w-full block 800px:flex items-center justify-between">
				<div className="w-full mb-4 800px:w-[35%] min-h-[20vh] bg-white shadow rounded px-2 py-5">
					<div className="flex items-center">
						<AiOutlineMoneyCollect size={30} className="mr-2" fill="#00000085" />
						<h3 className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] text-[#00000085]`}>
							Amount Earned <br />
							<span className="text-[16px]">(with 2% service charge after 1st month of sales)</span>
						</h3>
					</div>
					<h5 className="pt-2 pl-[36px] text-[22px] font-[500]">₦{availableBalance}</h5>

					{/* Shop link with copy icon */}
					<div className="flex items-center mt-4">
						<span className="text-[#077f9c] pr-2">{shopLink}</span>
						<br />
						<button
							className="bg-transparent border-none cursor-pointer"
							onClick={copyShopLinkToClipboard}
						>
							<AiOutlineCopy size={20} className="text-[#077f9c] hover:text-[#055e73] transition duration-300" />
						</button>
					</div>

					<Link to="/dashboard-withdraw-money">
						<h5 className="pt-4 pl-[2] text-[#077f9c]">Add bank details</h5>
					</Link>
				</div>

				<div className="w-full mb-4 800px:w-[30%] min-h-[20vh] bg-white shadow rounded px-2 py-5">
					<div className="flex items-center">
						<MdBorderClear size={30} className="mr-2" fill="#00000085" />
						<h3 className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] text-[#00000085]`}>
							All Orders
						</h3>
					</div>
					<h5 className="pt-2 pl-[36px] text-[22px] font-[500]">
						{orders && orders.length}
					</h5>
					<Link to="/dashboard-orders">
						<h5 className="pt-4 pl-2 text-[#077f9c]">View Orders</h5>
					</Link>
				</div>

				<div className="w-full mb-4 800px:w-[30%] min-h-[20vh] bg-white shadow rounded px-2 py-5">
					<div className="flex items-center">
						<AiOutlineMoneyCollect size={30} className="mr-2" fill="#00000085" />
						<h3 className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] text-[#00000085]`}>
							All Products
						</h3>
					</div>
					<h5 className="pt-2 pl-[36px] text-[22px] font-[500]">
						{products && products.length}
					</h5>
					<Link to="/dashboard-products">
						<h5 className="pt-4 pl-2 text-[#077f9c]">View Products</h5>
					</Link>
				</div>
			</div>
			<br />
			<h3 className="text-[22px] font-Poppins pb-2">Latest Orders</h3>
			<div className="w-full min-h-[45vh] bg-white rounded">
				<DataGrid
					rows={row}
					columns={columns}
					pageSize={10}
					disableSelectionOnClick
					autoHeight
				/>
			</div>
		</div>
	);
};

export default DashboardHero;
