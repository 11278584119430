import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import styles from "../../styles/styles";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { loadSeller } from "../../redux/actions/user";
import { AiOutlineDelete } from "react-icons/ai";

const WithdrawMoney = () => {
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();
	const { seller } = useSelector((state) => state.seller);
	const [paymentMethod, setPaymentMethod] = useState(false);
	const [withdrawAmount, setWithdrawAmount] = useState(50);
	const [bankInfo, setBankInfo] = useState({
		bankName: "",
		bankSwiftCode: null,
		bankAccountNumber: null,
	});

	const [allBanks, setAllBanks] = useState([]);
	const [filteredBanks, setFilteredBanks] = useState([]);
	const [showDropdown, setShowDropdown] = useState(false);

	const token = JSON.parse(localStorage.getItem("token"));
	useEffect(() => {
		dispatch(getAllOrdersOfShop(seller._id));
		fetchBanks();
	}, [dispatch]);

	const fetchBanks = async () => {
		try {
			const response = await axios.get("https://api.paystack.co/bank?currency=NGN");
			setAllBanks(response.data.data);
			setFilteredBanks(response.data.data);
		} catch (error) {
			console.error("Error fetching banks:", error);
		}
	};

	// Input field for bank holder name
	const handleSearch = (query) => {
		const filtered = allBanks.filter((bank) => bank.name.toLowerCase().includes(query.toLowerCase()));
		setFilteredBanks(filtered);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const bankAccountNumber = bankInfo.bankAccountNumber;
		const bankCode = bankInfo.bankSwiftCode;

		const response = await axios
			.get(
				`${server}/payment/verifyAccount?account_number=${bankAccountNumber}&bank_code=${bankCode}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.catch((err) => console.error(err?.response.data.message));

		if (!response.data.status) {
			alert(response.data.message);
			return;
		}

		alert(response.data.message);
		const bankHolderName = response.data?.data?.account_name;

		const withdrawMethod = {
			bankName: bankInfo.bankName,
			bankSwiftCode: bankCode,
			bankAccountNumber,
			bankHolderName,
		};

		setPaymentMethod(false);

		await axios
			.put(
				`${server}/shop/update-payment-methods`,
				{
					withdrawMethod,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((res) => {
				toast.success("Withdraw method added successfully!");
				dispatch(loadSeller());
				setBankInfo({
					bankName: "",
					bankSwiftCode: null,
					bankAccountNumber: null,
				});
			})
			.catch((error) => {
				console.log(error.response.data.message);
			});
	};

	const deleteHandler = async () => {
		await axios
			.delete(`${server}/shop/delete-withdraw-method`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((res) => {
				toast.success("Withdraw method deleted successfully!");
				dispatch(loadSeller());
			});
	};

	const error = () => {
		toast.error("You not have enough balance to withdraw!");
	};

	const withdrawHandler = async () => {
		alert("This feature is currently disabled!")
		// if (withdrawAmount < 100) {
		// 	toast.error("Withdrawal amount must be at least NGN100!");
		// } else if (parseInt(withdrawAmount) > parseInt(availableBalance)) {
		// 	toast.error("Insufficient balance for withdrawal!");
		// } else {
		// 	const amount = withdrawAmount;
		// 	await axios
		// 		.post(
		// 			`${server}/withdraw/create-withdraw-request`,
		// 			{ amount },
		// 			{
		// 				headers: {
		// 					Authorization: `Bearer ${token}`,
		// 				},
		// 			}
		// 		)
		// 		.then((res) => {
		// 			toast.success("Withdraw money request is successful!");
		// 		});
		// }
	};

	const availableBalance = seller?.availableBalance.toFixed(2);

	return (
		<div className="w-full h-[90vh] p-8 ml-10 800px:ml-0">
			<div className="w-full bg-white h-full rounded flex items-center justify-center flex-col">
				<h5 className="text-[20px] pb-4">Available Balance: ₦{availableBalance}</h5>
				<div
					className={`${styles.button} text-white !h-[42px] !rounded`}
					onClick={() => setOpen(true)}
				>
					Withdraw
				</div>
			</div>
			{open && (
				<div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
					<div
						className={`w-[95%] 800px:w-[50%] bg-white shadow rounded ${
							paymentMethod ? "h-[80vh] overflow-y-scroll" : "h-[unset]"
						} min-h-[40vh] p-3`}
					>
						<div className="w-full flex justify-end">
							<RxCross1
								size={25}
								onClick={() =>
									setOpen(false) || setPaymentMethod(false)
								}
								className="cursor-pointer"
							/>
						</div>
						{paymentMethod ? (
							<div>
								<h3 className="text-[22px] font-Poppins text-center font-[600]">
									Add new Withdraw Method
								</h3>
								<form onSubmit={handleSubmit}>
									<div>
										<label>
											Bank Name{" "}
											<span className="text-red-500">
												*
											</span>
										</label>
										<input
											type="text"
											name=""
											required
											value={bankInfo.bankName}
											onChange={(e) => {
												handleSearch(
													e.target.value
												);
												setBankInfo({
													...bankInfo,
													bankName: e
														.target
														.value,
												});
											}}
											onClick={() =>
												setShowDropdown(true)
											} // Show dropdown when input is clicked
											placeholder="Enter your Bank name!"
											className={`${styles.input} mt-2`}
										/>
										{showDropdown && (
											<div className="dropdown">
												{filteredBanks.map(
													(bank) => (
														<div
															key={
																bank.code
															}
															className="px-4 py-2 cursor-pointer hover:bg-gray-100"
															onClick={() => {
																setBankInfo(
																	{
																		...bankInfo,
																		bankName: bank.name,
																		bankSwiftCode:
																			bank.code,
																	}
																); // Set selected bank name and code
																setShowDropdown(
																	false
																); // Hide dropdown after selection
															}}
														>
															{
																bank.name
															}
														</div>
													)
												)}
											</div>
										)}
									</div>
									<div className="pt-2">
										<label>
											Bank CBN Code{" "}
											<span className="text-red-500">
												*
											</span>
										</label>
										<input
											type="text"
											name=""
											id=""
											required
											value={bankInfo.bankSwiftCode}
											onChange={(e) =>
												setBankInfo({
													...bankInfo,
													bankSwiftCode:
														e.target
															.value,
												})
											}
											placeholder="Enter your Bank Swift Code!"
											className={`${styles.input} mt-2`}
										/>
									</div>

									<div className="pt-2">
										<label>
											Bank Account Number{" "}
											<span className="text-red-500">
												*
											</span>
										</label>
										<input
											type="number"
											name=""
											id=""
											value={
												bankInfo.bankAccountNumber
											}
											onChange={(e) => {
												setBankInfo({
													...bankInfo,
													bankAccountNumber:
														e.target
															.value,
												});
											}}
											required
											placeholder="Enter your bank account number!"
											className={`${styles.input} mt-2`}
										/>
									</div>
									<br />
									<p>
										Note: The name on this bank account must
										match the name on your seller account.
									</p>
									<button
										type="submit"
										className={`${styles.button} mb-3 text-white`}
									>
										Add
									</button>
								</form>
							</div>
						) : (
							<>
								<h3 className="text-[22px] font-Poppins">
									Available Withdraw Methods:
								</h3>

								{seller && seller?.withdrawMethod ? (
									<div>
										<div className="800px:flex w-full justify-between items-center">
											<div className="800px:w-[50%]">
												<h5>
													Account Number:{" "}
													{"*".repeat(
														seller
															?.withdrawMethod
															.bankAccountNumber
															.length -
															3
													) +
														seller?.withdrawMethod.bankAccountNumber.slice(
															-3
														)}
												</h5>
												<h5>
													Bank Name:{" "}
													{
														seller
															?.withdrawMethod
															.bankName
													}
												</h5>
												<h5>
													Bank Holder
													Name:{" "}
													{
														seller
															?.withdrawMethod
															.bankHolderName
													}
												</h5>
											</div>
											<div className="800px:w-[50%]">
												<AiOutlineDelete
													size={25}
													className="cursor-pointer"
													onClick={() =>
														deleteHandler()
													}
												/>
											</div>
										</div>
										<br />
										<h4>
											Available Balance: ₦
											{availableBalance}
										</h4>
										<br />
										<div className="800px:flex w-full items-center">
											<input
												type="number"
												placeholder="Amount..."
												value={withdrawAmount}
												onChange={(e) =>
													setWithdrawAmount(
														e.target
															.value
													)
												}
												className="800px:w-[100px] w-[full] border 800px:mr-3 p-1 rounded"
											/>
											<div
												className={`${styles.button} !h-[42px] text-white`}
												onClick={
													withdrawHandler
												}
											>
												Withdraw
											</div>
										</div>
									</div>
								) : (
									<div>
										<p className="text-[18px] pt-2">
											No Withdraw Methods available!
										</p>
										<div className="w-full flex items-center">
											<div
												className={`${styles.button} text-[#fff] text-[18px] mt-4`}
												onClick={() =>
													setPaymentMethod(
														true
													)
												}
											>
												Add new
											</div>
										</div>
									</div>
								)}
							</>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default WithdrawMoney;
