import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { server } from "../../server";
import { addTocart } from "../../redux/actions/cart";
import { useDispatch } from "react-redux";


const EventDetails = ({ event }) => {
  const dispatch = useDispatch();
  const price = event?.discountPrice;
  const [ticket, setTicket] = useState(false);

  // Function to format date to "Day, Month Date" format
  const formatDate = (dateString) => {
    const options = { weekday: 'long', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-NG', options);
  };
  
  const getEvent = async (event) => {
    return await axios.get(`${server}/event/get-event/${event._id}`)
  }

  const addToCartHandler = async (event) => {
    const fetchEvent = await getEvent(event)
    const eventDetails = fetchEvent.data?.event

    if (ticket) {
      toast.error("Item already in cart!");
    } else {
      if (eventDetails.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = [{ ...eventDetails, qty: 1 }];
        dispatch(addTocart(...cartData));
        setTicket(true)
        toast.success("Item added to cart successfully!");
      }
    }
  };

  return (
    <div className="mx-auto px-4 sm:px-6 lg:px-8 py-8 max-w-7xl">
      <h1 className="text-4xl lg:text-5xl font-bold mb-4">{event?.name}</h1>

      {/* Event details */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">

        {/* Event image gallery */}
        <div className="space-y-4 lg:mr-8">
          {event?.images.map((image, index) => (
            <img
              key={index}
              src={image.url}
              alt={`Event ${index}`}
              className="w-full h-[350px] object-cover"
            />
          ))}
        </div>

        {/* Event details */}
        <div className="space-y-4">
          <h2 className="text-xl lg:text-2xl font-semibold mb-2">Event Details</h2>
          <p className="text-lg">{event?.description}</p>
          <div>
            <p>
              <span className="font-semibold mb-1 block lg:inline-block lg:mb-3">Location:</span>{" "}
              {event?.location}
            </p>
            <p>
              <span className="font-semibold mb-1 block lg:inline-block lg:mb-3">Time:</span>{" "}
              {event?.start_Time}
            </p>
            <p>
              <span className="font-semibold mb-1 block lg:inline-block lg:mb-3">Date:</span>{" "}
              {event?.event_date && formatDate(event.event_date)}
            </p>
          </div>
          <button
            className="w-full bg-blue-700 hover:bg-blue-800 text-white p-4 rounded-md text-2xl"
            onClick={() => addToCartHandler(event)}
          >
            {price && <p>₦{price}</p>}
            Get Ticket
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
