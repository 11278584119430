import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import { toast } from "react-toastify";
// import TicketDetails from "../components/Events/Ticket/TicketDetails";
import EventDetails from "../components/Events/EventDetails";
import axios from "axios";
import Header from "../components/Layout/Header";
import { server } from "../server";

const EventDetailsPage = () => {
  const { customName } = useParams();
  const dispatch = useDispatch();
  const [event, setEvent] = useState(null);
  // const { isLoading, error } = useSelector((state) => state.events);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${server}/event/get-event-by-custom-name/${customName}`);

        setEvent(response.data.event);
      } catch (error) {
        console.error("Error fetching event (unexpected):", error);
      }
    };

    fetchData();
  }, [dispatch, customName]);


  return (
    <>
      <Header />
      <div>
        <EventDetails event={event} />
      </div>

    </>
  );
};

export default EventDetailsPage;
