import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Layout/Header';
import { socketServer } from "../server";

const useWebSocket = (userId) => {
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.user);

    useEffect(() => {
        const socket = io(socketServer, { transports: ["websocket"] });
        console.log("socket", socket)
        
        // Join the socket room with userId
        socket.emit('addUser', userId);
        
        // Handle incoming messages
        socket.on('orderStatus', (data) => {
            console.log(data)

            const { userId, orderStatus, order } = data

            console.log("userId", userId, "user._id", user._id)
            if (userId !== user._id) return;
            
            if (orderStatus === 'ORDER_CREATED') {
                localStorage.removeItem("ticket_name");

                navigate('/order/success');

                localStorage.setItem("cartItems", JSON.stringify([]));
                localStorage.setItem("latestOrder", JSON.stringify(order));
    
                window.location.reload();
            }
        });

        socket.on('connect', () => {
            console.log("Socket connected");
        });

        socket.on('disconnect', () => {
            console.log("Socket disconnected");
        });

        return () => {
            socket.disconnect();
        };

    }, [navigate, userId]);
};

const OrderProcessing = () => {
    const { user } = useSelector((state) => state.user);
    const userId = user?._id;

    useWebSocket(userId);

    return (
        <>
            <Header />
            <div className="flex flex-col justify-center items-center h-screen px-4 text-center">
                <h2 className="text-2xl font-semibold mb-4">Processing Your Order...</h2>
                <div className="loader border-t-4 border-blue-500 border-solid rounded-full w-12 h-12 mb-4 animate-spin"></div>
                <p className="text-gray-600">Please wait while we confirm your payment.</p>
                <p className="text-gray-600">Or kindly Check you Inbox.</p>
            </div>
        </>
    );
};

export default OrderProcessing;
